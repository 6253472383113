import "reflect-metadata";
import "@/styles/globals.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { SessionProvider, useSession } from "next-auth/react";
import Navigation from "@/components/Navigation/Navigation";
import { DM_Sans } from "next/font/google";
import { trpc } from "../utils/trpc";
import clsx from "clsx";
import { useState } from "react";
import type { UserOrganizations } from "@/contexts/global/OrganizationContext";
import {
  UserOrganizationContext,
  UserOrganizationDispatchContext,
} from "@/contexts/global/OrganizationContext";
import type { OrganizationBrands } from "@/contexts/global/BrandContext";
import {
  OrganizationBrandsContext,
  OrganizationBrandsDispatchContext,
} from "@/contexts/global/BrandContext";
import Footer from "@/components/Navigation/Footer";
import { Toaster } from "@/components/ui/sonner";
import CookieConsent from "@/components/CookieConsent/CookieConsent";
import FullstoryProvider from "@/components/Providers/FullstoryProvider";
import PostHogProvider from "@/components/Providers/PostHogProvider";
import { cn } from "@/lib/utils";
import { GodViewProvider } from "@/contexts/global/GodViewContext";
import { GodSettings } from "@/components/God/GodSettings";

const TITLE = "Treater";

const nunito = DM_Sans({
  subsets: ["latin", "latin-ext"],
  weight: ["400", "500", "700"],
  variable: "--font-main",
});

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [userOrganizations, setUserOrganizations] = useState<UserOrganizations | null>(null);
  const [organizationBrands, setOrganizationBrands] = useState<OrganizationBrands | null>(null);
  const isIndex = router.pathname === "/";

  const subTitle = isIndex ? undefined : ((Component as any).title ?? pageProps.title);
  const title = subTitle ? `${subTitle} - ${TITLE}` : TITLE;
  const noNav = (Component as any).noNav ?? pageProps.noNav ?? false;
  const noLinks = (Component as any).noNavLinks ?? pageProps.noNavLinks ?? false;
  const includeGoToAccount =
    (Component as any).includeGoToAccount ?? pageProps.includeGoToAccount ?? false;
  const maxHScreen = (Component as any).maxHScreen ?? pageProps.maxHScreen ?? false;
  const mainContentClassName =
    (Component as any).mainContentClassName ?? pageProps.mainContentClassName;

  return (
    <SessionProvider session={pageProps.session}>
      <TreaterHead title={title} pageProps={pageProps} />
      <GodViewProvider>
        <UserOrganizationContext.Provider value={userOrganizations}>
          <UserOrganizationDispatchContext.Provider value={setUserOrganizations}>
            <OrganizationBrandsContext.Provider value={organizationBrands}>
              <OrganizationBrandsDispatchContext.Provider value={setOrganizationBrands}>
                <EnsureAuthSettled>
                  <PostHogProvider>
                    <FullstoryProvider />
                    <TreaterBody
                      noNav={noNav}
                      noLinks={noLinks}
                      includeGoToAccount={includeGoToAccount}
                      maxHScreen={maxHScreen}
                      mainContentClassName={mainContentClassName}
                    >
                      <Component {...pageProps}></Component>
                      <GodSettings />
                    </TreaterBody>
                  </PostHogProvider>
                </EnsureAuthSettled>
              </OrganizationBrandsDispatchContext.Provider>
            </OrganizationBrandsContext.Provider>
          </UserOrganizationDispatchContext.Provider>
        </UserOrganizationContext.Provider>
      </GodViewProvider>
    </SessionProvider>
  );
}

function EnsureAuthSettled({ children }: React.PropsWithChildren) {
  const session = useSession();
  if (session.status === "loading") return <>Loading...</>;
  return <>{children}</>;
}

function TreaterHead({ title, pageProps }: { title: string; pageProps: AppProps["pageProps"] }) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={pageProps.description}></meta>
      <meta property="og:type" content="website"></meta>
      <meta property="og:title" content={title}></meta>
      <meta property="og:description" content={pageProps.description}></meta>
      <meta property="og:image" content="https://trytreater.com/treater.png"></meta>
      <meta property="og:url" content="https://trytreater.com"></meta>
      <meta name="twitter:card" content="summary_large_image"></meta>
      <meta name="twitter:site" content="@trytreater"></meta>
      <meta name="twitter:creator" content="@trytreater"></meta>
      <meta name="twitter:title" content={title}></meta>
      <meta
        name="twitter:description"
        content={pageProps.description || "Treater drives retail velocity for CPG brands."}
      ></meta>
      <meta name="twitter:image" content="https://trytreater.com/treater.png"></meta>
      <link rel="icon" href="/favicon.ico" />
      <link rel="canonical" href="https://trytreater.com"></link>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    </Head>
  );
}

function TreaterBody({
  children,
  noNav,
  noLinks,
  includeGoToAccount,
  maxHScreen = false,
  mainContentClassName,
}: {
  children: React.ReactNode;
  noNav?: boolean;
  noLinks?: boolean;
  includeGoToAccount?: boolean;
  maxHScreen?: boolean;
  mainContentClassName?: string;
}) {
  return (
    <>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <style jsx global>{`
        html {
          font-family: ${nunito.style.fontFamily};
        }
      `}</style>
      <div
        className={clsx(
          `flex flex-col antialiased ${nunito.variable} font-sans`,
          maxHScreen ? "h-screen" : "min-h-screen",
        )}
      >
        <Toaster />
        {!noNav ? <Navigation noLinks={noLinks} includeGoToAccount={includeGoToAccount} /> : <></>}
        <div className={cn("flex flex-1", mainContentClassName)}>{children}</div>
        <Footer />
        <CookieConsent />
      </div>
    </>
  );
}

export default trpc.withTRPC(App);
