import { useRouter } from "next/router";
import Link from "next/link";
import Logo from "../Logo";
import { useSession } from "next-auth/react";
import { PAGES } from "@/utils/pages";
import type { UserOrganizations } from "@/contexts/global/OrganizationContext";
import { useUserOrganizations } from "@/contexts/global/OrganizationContext";
import type { OrganizationBrands } from "@/contexts/global/BrandContext";
import { useOrganizationBrands } from "@/contexts/global/BrandContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { PersonIcon } from "@radix-ui/react-icons";
import React from "react";
import { cn } from "@/lib/utils";
import { buttonVariants } from "../ui/button";
import type { NavigationLink, NavigationGroup } from "@/types/NavigationTypes";
import { useNavigationConfig } from "@/hooks/useNavigationConfig";

interface ListItemProps extends React.ComponentPropsWithoutRef<"a"> {
  title: string;
  description?: string;
}

const ListItem = React.forwardRef<React.ElementRef<"a">, ListItemProps>(
  ({ className, title, description, children, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <a
            ref={ref}
            className={cn(
              "block select-none space-y-1 p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className,
            )}
            {...props}
          >
            <div className="text-sm font-medium leading-none">{title}</div>
            {description && (
              <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                {description}
              </p>
            )}
          </a>
        </NavigationMenuLink>
      </li>
    );
  },
);
ListItem.displayName = "ListItem";

export default function Navigation({
  noLinks,
  includeGoToAccount,
}: {
  noLinks?: boolean;
  includeGoToAccount?: boolean;
}) {
  return (
    <header className="bg-white">
      <nav className="flex items-center justify-between py-4 px-8">
        <div className="flex items-center gap-8">
          <Link href="/" className="">
            <Logo width="w-12" />
          </Link>
          {!noLinks && !includeGoToAccount && <AppLinks />}
        </div>
        {includeGoToAccount ? (
          <div className="flex">
            <GoToAccount />
          </div>
        ) : (
          !noLinks && (
            <div className="flex items-center">
              <SessionLinks />
            </div>
          )
        )}
      </nav>
    </header>
  );
}

function isUrlActive(currentPath: string, url: string): boolean {
  return new URL(currentPath + "/z", "http://example.com").pathname.startsWith(
    new URL(url + "/z", "http://example.com").pathname,
  );
}

function isGroupActive(currentPath: string, group: NavigationGroup): boolean {
  return group.items.some((item) => isUrlActive(currentPath, item.url));
}

function AppLinks() {
  const userOrganizations = useUserOrganizations();
  const organizationBrands = useOrganizationBrands();
  const router = useRouter();
  const path = router.asPath;
  const [selectedItem, setSelectedItem] = React.useState<string | null>(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isNavigating, setIsNavigating] = React.useState(false);

  React.useEffect(() => {
    const handleStart = () => setIsNavigating(true);
    const handleComplete = () => setIsNavigating(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router]);

  const orgSlug = userOrganizations?.active.organization.slug ?? "";
  const brandName = organizationBrands?.active.name ?? "";
  const config = useNavigationConfig(orgSlug, brandName);

  if (!userOrganizations || !organizationBrands) return null;

  const handleMenuItemSelect = (itemUrl: string) => {
    setSelectedItem(itemUrl);
  };

  const handleItemClick = () => {
    setIsNavigating(true);
    setSelectedItem(null);
  };

  const isItemActive = (url: string) => {
    if (isNavigating) return false;
    if (isMenuOpen && selectedItem !== null) {
      return selectedItem === url;
    }
    return isUrlActive(path, url);
  };

  return (
    <NavigationMenu onValueChange={(value) => setIsMenuOpen(!!value)}>
      <NavigationMenuList>
        {config.mainLinks.map((item, index) => (
          <NavigationMenuItem key={index}>
            {"url" in item ? (
              <Link href={item.url} legacyBehavior passHref>
                <NavigationMenuLink
                  className={cn(
                    navigationMenuTriggerStyle(),
                    isItemActive(item.url) && "bg-accent text-accent-foreground",
                    item.className,
                  )}
                  onMouseEnter={() => handleMenuItemSelect(item.url)}
                  onClick={handleItemClick}
                >
                  {item.title}
                </NavigationMenuLink>
              </Link>
            ) : (
              <>
                <NavigationMenuTrigger
                  className={cn(
                    navigationMenuTriggerStyle(),
                    isMenuOpen && selectedItem === item.title && "bg-accent text-accent-foreground",
                    !isMenuOpen && isGroupActive(path, item) && "bg-accent text-accent-foreground",
                    item.className,
                  )}
                  onMouseEnter={() => handleMenuItemSelect(item.title)}
                >
                  {item.title}
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="grid w-[400px] gap-3 p-4 md:w-[500px] md:grid-cols-2 lg:w-[600px]">
                    {item.items.map((subItem, subIndex) => (
                      <ListItem
                        key={subIndex}
                        href={subItem.url}
                        title={subItem.title}
                        description={subItem.description}
                        className={cn(
                          isItemActive(subItem.url) && "bg-accent text-accent-foreground",
                        )}
                        onMouseEnter={() => handleMenuItemSelect(subItem.url)}
                        onClick={handleItemClick}
                      />
                    ))}
                  </ul>
                </NavigationMenuContent>
              </>
            )}
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
}

function SessionLinks() {
  const session = useSession();

  if (!session.data) return <AnonymousSessionLinks />;

  return <ActiveSessionLinks />;
}

function GoToAccount() {
  const session = useSession();

  if (!session.data) return <AnonymousSessionLinks />;

  return (
    <Link className={buttonVariants({ variant: "outline" })} href={PAGES.ORGANIZATIONS}>
      Go to account
    </Link>
  );
}

function AnonymousSessionLinks() {
  return (
    <div className="flex flex-row space-x-4">
      <Link className={buttonVariants({ variant: "outline" })} href={PAGES.AUTH.SIGN_IN}>
        Sign in
      </Link>
    </div>
  );
}

function ActiveSessionLinks() {
  const userOrganizations = useUserOrganizations();
  const organizationBrands = useOrganizationBrands();
  const config = useNavigationConfig(
    userOrganizations?.active.organization.slug ?? "",
    organizationBrands?.active.name ?? "",
  );

  const isActiveInBrand = !!userOrganizations && !!organizationBrands;

  return (
    <div className="flex flex-row space-x-4">
      {isActiveInBrand && (
        <OrganizationLinks
          userOrganizations={userOrganizations}
          organizationBrands={organizationBrands}
        />
      )}
      <ProfileLinks links={isActiveInBrand ? config.profileLinks : []} />
    </div>
  );
}

function OrganizationLinks({
  userOrganizations,
  organizationBrands,
}: {
  userOrganizations: UserOrganizations;
  organizationBrands: OrganizationBrands;
}) {
  const hasMultipleOrganizations = userOrganizations.available.length > 1;
  const hasMultipleBrands = organizationBrands.available > 1;

  const showDropdown = hasMultipleOrganizations || hasMultipleBrands;

  const contents = [];
  if (userOrganizations.available.length > 1) {
    contents.push(
      <Link className="cursor-pointer hover:text-blue-link" href={PAGES.ORGANIZATIONS}>
        Switch organization
      </Link>,
    );
  }
  if (organizationBrands.available > 1) {
    contents.push(
      <Link
        className="cursor-pointer hover:text-blue-link"
        href={PAGES.APP.INDEX(userOrganizations.active.organization.slug)}
      >
        Switch brand
      </Link>,
    );
  }

  const picture = (
    <picture className={cn(showDropdown && "cursor-pointer")}>
      <img
        className="h-12 w-12 object-cover"
        alt={organizationBrands.active.name}
        src={organizationBrands.active.primaryImageUrl}
      />
    </picture>
  );

  if (contents.length === 0) {
    return picture;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>{picture}</DropdownMenuTrigger>
      <DropdownMenuContent>
        {contents.map((content, i) => (
          <React.Fragment key={i}>
            {i > 0 && <DropdownMenuSeparator />}
            <DropdownMenuItem>{content}</DropdownMenuItem>
          </React.Fragment>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function ProfileLinks({ links }: { links: NavigationLink[] }) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <PersonIcon className="h-6 w-6" />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {links.map(({ title, url }, i) => (
          <React.Fragment key={i}>
            {i > 0 && <DropdownMenuSeparator />}
            <DropdownMenuItem>
              <Link className="cursor-pointer hover:text-blue-link whitespace-nowrap" href={url}>
                {title}
              </Link>
            </DropdownMenuItem>
          </React.Fragment>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
