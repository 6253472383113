import { useEffect, useState } from "react";
import { useGodView } from "@/contexts/global/GodViewContext";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useSession } from "next-auth/react";
import { isGod } from "@/service/users/gods";
import { EyeOpenIcon, EyeNoneIcon } from "@radix-ui/react-icons";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

const GODMODE_KEY_SEQUENCE = "godmode";

export function GodSettings() {
  const session = useSession();
  const authenticatedAsGod = isGod(session.data?.user.email ?? "");
  const [isOpen, setIsOpen] = useState(false);
  const { forceDisableGodView, setForceDisableGodView } = useGodView();
  const [keySequence, setKeySequence] = useState("");

  useEffect(() => {
    if (!authenticatedAsGod) return;

    const handleKeyPress = (event: KeyboardEvent) => {
      const newSequence = keySequence + event.key;
      setKeySequence(newSequence);

      if (newSequence.endsWith(GODMODE_KEY_SEQUENCE)) {
        setIsOpen(true);
        setKeySequence("");
      } else if (newSequence.length > GODMODE_KEY_SEQUENCE.length) {
        setKeySequence(newSequence.slice(-GODMODE_KEY_SEQUENCE.length));
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [keySequence, authenticatedAsGod]);

  if (!authenticatedAsGod) return null;

  const toggleGodView = () => {
    setForceDisableGodView(!forceDisableGodView);
  };

  return (
    <>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div
              className="fixed bottom-2 left-2 z-50 hover:opacity-100 transition-opacity cursor-pointer"
              onClick={toggleGodView}
            >
              {!forceDisableGodView ? (
                <EyeOpenIcon className="w-3 h-3 text-main-500" />
              ) : (
                <EyeNoneIcon className="w-3 h-3 text-muted-foreground" />
              )}
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>God View {!forceDisableGodView ? "Enabled" : "Disabled"}</p>
            <p className="text-xs text-muted-foreground">Type &quot;godmode&quot; to change</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="max-w-sm">
          <DialogHeader>
            <DialogTitle>God Mode Settings</DialogTitle>
          </DialogHeader>
          <div className="py-4">
            <div className="flex items-center space-x-2">
              <Switch
                id="god-view"
                checked={!forceDisableGodView}
                onCheckedChange={(checked) => setForceDisableGodView(!checked)}
              />
              <Label htmlFor="god-view">Enable God View</Label>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
