import { createContext, useContext, useState, useEffect } from "react";

const GOD_VIEW_STORAGE_KEY = "treater:god-view-disabled";

interface GodViewContextType {
  forceDisableGodView: boolean;
  setForceDisableGodView: (value: boolean) => void;
}

const GodViewContext = createContext<GodViewContextType | null>(null);

export function GodViewProvider({ children }: { children: React.ReactNode }) {
  // Initialize from localStorage, defaulting to false if not set
  const [forceDisableGodView, setForceDisableGodViewState] = useState(() => {
    // Only run this function once during initialization
    if (typeof window === "undefined") return false;
    const stored = localStorage.getItem(GOD_VIEW_STORAGE_KEY);
    return stored ? JSON.parse(stored) : false;
  });

  // Persist to localStorage whenever the value changes
  useEffect(() => {
    localStorage.setItem(GOD_VIEW_STORAGE_KEY, JSON.stringify(forceDisableGodView));
  }, [forceDisableGodView]);

  const setForceDisableGodView = (value: boolean) => {
    setForceDisableGodViewState(value);
  };

  return (
    <GodViewContext.Provider value={{ forceDisableGodView, setForceDisableGodView }}>
      {children}
    </GodViewContext.Provider>
  );
}

export function useGodView() {
  const context = useContext(GodViewContext);
  if (!context) {
    throw new Error("useGodView must be used within a GodViewProvider");
  }
  return context;
}
