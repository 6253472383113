const GODS = [
  "ayaz@trytreater.com",
  "britt@trytreater.com",
  "will@trytreater.com",
  "sayd@trytreater.com",
];

export function isGod(email: string) {
  return GODS.includes(email);
}
