import { PAGES } from "@/utils/pages";
import type { NavigationConfig } from "@/types/NavigationTypes";
import { useIsGod } from "./useIsGod";

export function useNavigationConfig(orgSlug: string, brandName: string): NavigationConfig {
  const isGod = useIsGod();

  return {
    mainLinks: [
      ...(isGod
        ? [
            {
              title: "God View",
              url: PAGES.APP.GOD.INDEX,
              className: "bg-main-50",
            },
          ]
        : []),
      {
        title: "Summary",
        url: PAGES.APP.DEFAULT_TARGET(orgSlug, brandName),
      },
      {
        title: "Insights",
        items: [
          {
            title: "Return on Investment",
            description: "View your brand's return on investment",
            url: PAGES.APP.INSIGHTS.ROI(orgSlug, brandName),
          },
          {
            title: "Historical Sales",
            description: "View your brand's historical sales",
            url: PAGES.APP.INSIGHTS.HISTORICAL_SALES(orgSlug, brandName),
          },
          {
            title: "Store Insights",
            description: "View your brand's store insights",
            url: PAGES.APP.INSIGHTS.STORE_INSIGHTS(orgSlug, brandName),
          },
          {
            title: "Insights Playground",
            description: "Ask questions about store conversations",
            url: PAGES.APP.INSIGHTS.STORE_INSIGHTS_PLAYGROUND(orgSlug, brandName),
          },
          {
            title: "Demographics",
            description: "View your brand's demographics",
            url: PAGES.APP.INSIGHTS.DEMOGRAPHICS(orgSlug, brandName),
          },
        ],
      },
      {
        title: "Stores & Promotions",
        items: [
          {
            title: "Store Search",
            description: "View and search for individual stores",
            url: PAGES.APP.STORES.INDEX(orgSlug, brandName),
          },
          {
            title: "Promotions",
            description: "Manage and track promotional campaigns",
            url: PAGES.APP.PROMOTIONS.INDEX(orgSlug, brandName),
          },
          {
            title: "Demos",
            description: "Schedule and manage store demos",
            url: PAGES.APP.DEMOS.INDEX(orgSlug, brandName),
          },
        ],
      },
      {
        title: "Distributors",
        items: [
          {
            title: "Inventory Forecast",
            description: "View DC inventory forecasts and manage low on-hands",
            url: PAGES.APP.DISTRIBUTORS.INVENTORY_FORECAST(orgSlug, brandName),
          },
        ],
      },
      {
        title: "Settings",
        url: PAGES.APP.BRAND_SETTINGS(orgSlug, brandName),
      },
    ],
    profileLinks: [
      { title: "Sign out", url: PAGES.AUTH.SIGN_OUT },
      { title: "Settings", url: PAGES.SETTINGS },
    ],
  };
}
